var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('a',{on:{"click":_setup.openModal}},[_vm._v(" "+_vm._s(_setup.selectedRow?.title || _setup.props.title)+" ")]),_c('a-modal',{attrs:{"title":_setup.props.title,"width":"1200px"},on:{"ok":_setup.ok},model:{value:(_setup.visble),callback:function ($$v) {_setup.visble=$$v},expression:"visble"}},[_c('a-form',{staticClass:"filter-form",attrs:{"layout":"inline","form":_setup.form},on:{"submit":_setup.search}},[_c('a-form-item',{attrs:{"colon":false,"label":"商品id"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['product_id']),expression:"['product_id']"}],staticClass:"input",attrs:{"allowClear":"","placeholder":"请输入"}})],1),_c('a-form-item',{attrs:{"colon":false,"label":"商品名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['product_name']),expression:"['product_name']"}],staticClass:"input",attrs:{"allowClear":"","placeholder":"请输入"}})],1),_c('a-form-item',{attrs:{"colon":false,"label":"商品分类"}},[_c(_setup.NCascader,{directives:[{name:"decorator",rawName:"v-decorator",value:(['category_id']),expression:"['category_id']"}],attrs:{"field-names":{ label: 'name', value: 'id', children: 'children' },"options":_setup.ware_category_list,"placeholder":"请选择"}})],1),_c('a-form-item',{attrs:{"colon":false,"label":"状态"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'is_show',
            {
              initialValue: 0,
            },
          ]),expression:"[\n            'is_show',\n            {\n              initialValue: 0,\n            },\n          ]"}]},[_c('a-select-option',{key:"0",attrs:{"value":0}},[_vm._v("全部")]),_c('a-select-option',{key:"1",attrs:{"value":1}},[_vm._v("已上架")]),_c('a-select-option',{key:"2",attrs:{"value":2}},[_vm._v("已下架")])],1)],1),_c('div',[_c('a-button',{attrs:{"type":"primary","htmlType":"submit","block":""}},[_vm._v("搜索")]),_c('a-button',{attrs:{"type":"link"},on:{"click":_setup.clearForm}},[_vm._v("清空搜索条件")])],1)],1),_c('a-table',{attrs:{"data-source":_setup.dataSource,"pagination":_setup.pagination,"rowKey":"id","loading":_setup.tableloading,"row-selection":{
        type: 'radio',
        selectedRowKeys: _setup.selectedRowKeys,
        onChange: _setup.onSelectChange,
      },"scroll":{ y: 240 }},on:{"change":_setup.onPagination}},[_c('a-table-column',{attrs:{"title":"商品ID","width":"8%"},scopedSlots:_vm._u([{key:"default",fn:function(record){return [_vm._v(" "+_vm._s(record.id))]}}])}),_c('a-table-column',{attrs:{"title":"商品图片"},scopedSlots:_vm._u([{key:"default",fn:function(record){return [_c('div',{staticClass:"ware-field"},[_c('img',{staticClass:"normal-image",attrs:{"src":record.banner_url_list[0]}})])]}}])}),_c('a-table-column',{attrs:{"title":"商品名称"},scopedSlots:_vm._u([{key:"default",fn:function(record){return [_c('div',{staticClass:"ware-field"},[_c('div',[_c('div',[_vm._v(_vm._s(record.title))]),_c('div',{staticClass:"ware-subtitle"},[_vm._v(" "+_vm._s(record.category.parent_category)+" "+_vm._s(record.category.name)+" ")])])])]}}])}),_c('a-table-column',{attrs:{"title":"商品价格"},scopedSlots:_vm._u([{key:"default",fn:function(record){return [_c('div',{staticClass:"ware-field"},[_c('span',[_vm._v(_vm._s(`¥ ${(record.price / 100).toFixed(2)}`))])])]}}])}),_c('a-table-column',{attrs:{"title":"发货仓库","width":"12%"},scopedSlots:_vm._u([{key:"default",fn:function(record){return _vm._l((record.warehouse),function(item){return _c('div',{key:item.id},[_vm._v(" "+_vm._s(item.warehouse_info ? item.warehouse_info.name : '')+" ")])})}}])}),_c('a-table-column',{attrs:{"title":"剩余库存","width":"8%"},scopedSlots:_vm._u([{key:"default",fn:function(record){return _vm._l((record.warehouse),function(item,index){return _c('div',{key:item.id},[_vm._v(" "+_vm._s(item.stock)+" ")])})}}])}),_c('a-table-column',{attrs:{"title":"状态","width":"8%"},scopedSlots:_vm._u([{key:"default",fn:function(record){return [_c('span',[_vm._v(_vm._s(_setup.STATUS_LIST[record.is_show].label))])]}}])}),_c('a-table-column',{attrs:{"title":"创建时间","width":"8%"},scopedSlots:_vm._u([{key:"default",fn:function(record){return [_vm._v(" "+_vm._s(_setup.formatDate(record.create_time)))]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }