var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('section',[_c('h2',{attrs:{"id":"subject"}},[_vm._v("新增礼品码")]),_c('a-form',{staticClass:"context",attrs:{"form":_setup.form,"label-col":{ span: 3 },"wrapper-col":{ span: 18 },"labelAlign":"left"},on:{"submit":_setup.onSave}},[_c('a-form-item',{attrs:{"colon":false,"label":"礼品名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'title',
            {
              rules: [{ required: true, message: '请输入礼品名称' }],
            },
          ]),expression:"[\n            'title',\n            {\n              rules: [{ required: true, message: '请输入礼品名称' }],\n            },\n          ]"}],staticClass:"input",attrs:{"allowClear":"","placeholder":"请输入"}})],1),_c('a-form-item',{attrs:{"colon":false,"label":"礼品编码前缀"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'prefix',
            {
              rules: [{ required: true, message: '' }, { validator: _setup.validator }],
            },
          ]),expression:"[\n            'prefix',\n            {\n              rules: [{ required: true, message: '' }, { validator: validator }],\n            },\n          ]"}],staticClass:"input",attrs:{"allowClear":"","placeholder":"请输入"}}),_c('span',{staticStyle:{"color":"#d59740"}},[_vm._v("示例：「NLDX」202201109231前缀")])],1),_c('a-form-item',{attrs:{"colon":false,"label":"发放数量"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'release_number',
            {
              rules: [{ required: true, message: '请输入发放数量' }],
            },
          ]),expression:"[\n            'release_number',\n            {\n              rules: [{ required: true, message: '请输入发放数量' }],\n            },\n          ]"}],attrs:{"max":10000000,"allowClear":"","placeholder":"请输入"}}),_vm._v("张 "),_c('div',[_vm._v("总数只能增加不能减少")])],1),_c('a-form-item',{attrs:{"colon":false,"label":"适用商品"}},[_c(_setup.CommoditySelector,{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'product_id',
            {
              rules: [{ required: true, message: '请选择商品' }],
            },
          ]),expression:"[\n            'product_id',\n            {\n              rules: [{ required: true, message: '请选择商品' }],\n            },\n          ]"}],attrs:{"title":"选择商品"}})],1),_c('a-form-item',{attrs:{"colon":false,"label":"有效期","required":""}},[_c('a-form-item',{style:({ display: 'inline-block' }),attrs:{"colon":false}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'start_time',
              {
                rules: [{ required: true, message: '请输入开始时间' }],
              },
            ]),expression:"[\n              'start_time',\n              {\n                rules: [{ required: true, message: '请输入开始时间' }],\n              },\n            ]"}],attrs:{"showTime":"","disabled-date":_setup.disabledStartDate,"disabled-time":_setup.disabledStartTime}})],1),_vm._v(" 至 "),_c('a-form-item',{style:({ display: 'inline-block' }),attrs:{"colon":false}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'end_time',
              {
                rules: [{ required: true, message: '请输入结束时间' }],
              },
            ]),expression:"[\n              'end_time',\n              {\n                rules: [{ required: true, message: '请输入结束时间' }],\n              },\n            ]"}],attrs:{"showTime":"","disabled-date":_setup.disabledEndDate}})],1)],1),_c('a-button',{staticClass:"btn",attrs:{"type":"primary","htmlType":"submit"}},[_vm._v("确认")]),_c('a-button',{staticClass:"btn",on:{"click":function($event){return _vm.$router.back()}}},[_vm._v("关闭")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }